<template>
  <div class="content">
    <progress-bar :percent="20" />
    <OKSVG class="ok-icon" />
    <div class="info-text">SolShare detected</div>

    <yellow-button
      data-tn="start-configuration-button"
      @click="startConfigurationClicked"
    >
      START CONFIGURATION
    </yellow-button>
    <back-button :style="{ textAlign: 'left' }" :step="-2" />

    <!--  Modal  -->

    <yellow-modal
      v-model="noteVisible"
      :target="WifiHighlightedSVG"
      right-arrow="nav-wifi"
      :closable="false"
      :top="150"
    >
      <div
        :style="{
          fontFamily: 'Helvetica Medium',
          fontSize: '25px',
          textAlign: 'center',
        }"
      >
        Lastly,
      </div>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '15px',
          marginBottom: '40px',
        }"
      >
        This icon will indicate the SolShare’s internet connection. Tap the icon
        to be taken to the Wi-Fi information page
      </div>
      <white-button
        data-tn="wifi-icon-modal-got-it"
        :block="false"
        :style="{ margin: '0 auto' }"
        @click="noteClicked"
      >
        OK, GOT IT
      </white-button>
    </yellow-modal>
  </div>
</template>

<script>
import OKSVG from "../assets/svg/ok.svg";
import WifiHighlightedSVG from "../assets/svg/wifi-highlighted.svg";
import { SOLAR_DELIVERY_METHOD } from "../util/constants";

export default {
  components: { OKSVG },
  data() {
    return {
      WifiHighlightedSVG,
      noteVisible: false,
    };
  },
  methods: {
    startConfigurationClicked() {
      this.noteVisible = true;
    },
    noteClicked() {
      this.noteVisible = false;
      if (this.$store.getters.is2P) {
        this.$store.commit(
          "setDeliveryMethod",
          SOLAR_DELIVERY_METHOD.NET_METERING
        );
      }
      if (this.$store.getters.shouldUpdateSoftware) {
        if (this.$store.getters.shouldNotShowUpdateInstructions) {
          this.$router.push("/software-update");
        } else {
          this.$router.push("/software-update-instruction");
        }
      } else {
        this.$router.push("/commission/10");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .ok-icon {
    margin-top: 68px;
    margin-bottom: 16px;
  }
  .info-text {
    color: #005149;
    margin-bottom: 63px;
  }
}
</style>
