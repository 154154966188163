var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 60
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Which CT's were used for this SolShare?")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-75a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A75);
      }
    }
  }, [_vm._v(" 75A " + _vm._s(_vm.$store.getters.is75A ? "(DEFAULT)" : "") + " ")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-120a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A120);
      }
    }
  }, [_vm._v(" 120A " + _vm._s(!_vm.$store.getters.is2P && !_vm.$store.getters.is75A ? "(DEFAULT)" : "") + " ")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-200a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A200);
      }
    }
  }, [_vm._v(" 200A " + _vm._s(_vm.$store.getters.is2P ? "(DEFAULT)" : "") + " ")]), _c('WarningSVG'), _c('span', {
    staticClass: "small-text"
  }, [_vm._v("If unsure, click the ‘Unsure’ button below:")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "to": "/commission/ct-rating-unsure",
      "bg-color": "rgba(229,248,240,0.7)"
    }
  }, [_vm._v(" UNSURE ")]), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }