<template>
  <a-button
    :data-tn="dataTn"
    shape="round"
    class="white-button"
    :block="block"
    size="large"
    :style="{
      border: bordered ? '1px solid #005149' : 0,
      background: selected ? textColor : bgColor,
      color: selected ? bgColor : textColor,
    }"
    :loading="loading"
    @click="onClick"
  >
    <slot />
  </a-button>
</template>

<script>
export default {
  name: "WhiteButton",
  props: {
    to: { type: String, default: null },
    replace: { type: String, default: null },
    go: { type: Number, default: null },
    selected: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    block: { type: Boolean, default: true },
    bgColor: { type: String, default: "#fff" },
    dataTn: { type: String, default: null },
    textColor: { type: String, default: "#005149" },
    loading: { type: Boolean, default: false },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to).catch(console.log);
      } else if (this.replace) {
        this.$router.replace(this.replace);
      } else if (this.go) {
        this.$router.go(this.go);
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.white-button {
  font-family: Helvetica, sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
