<template>
  <div class="content">
    <progress-bar :percent="70" />
    <div class="info-text" data-tn="commission-failed-info">
      Oh no! We’ve identified an issue with your installation.<br />
      Please take the required actions and reconfigure:
    </div>

    <div v-if="logs.length > 0" class="logs" data-tn="commission-failed-log">
      <div
        v-for="(log, index) of logs"
        :key="index"
        class="log"
        :data-tn="`commission-failed-detail-${index}`"
      >
        {{ log }}
      </div>
    </div>

    <white-button
      bordered
      :loading="loading"
      data-tn="commission-failed-try-again"
      @click="tryAgainClicked"
    >
      OK, TRY AGAIN
    </white-button>
    <yellow-button
      to="/commission/11"
      class="yellow-button"
      data-tn="commission-failed-reconfigure"
    >
      RECONFIGURE
    </yellow-button>
  </div>
</template>

<script>
import { COMMISSION_STAGE, COMMISSION_STATUS } from "../util/constants";

import { ERROR_MAP } from "../util/error-report";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    logs() {
      if (this.$store.state.commissionLog.status === COMMISSION_STATUS.FAILED) {
        return this.$store.state.commissionLog.logs
          .map(({ errorcode, serial, country, con_name, other_con }) => {
            con_name = this.mapAddress(this.mapPhaseNames(con_name));
            other_con = this.mapAddress(this.mapPhaseNames(other_con));
            serial = this.$store.state.project.serialNumber;
            country = this.$store.state.project.address.country;
            return ERROR_MAP[errorcode](serial, country, con_name, other_con);
          })
          .sort((a, b) => a.localeCompare(b));
      } else if (
        this.$store.state.commissionLog.status === COMMISSION_STATUS.PENDING
      ) {
        return ["Commissioning process timeout, please contact Allume"];
      } else {
        return [];
      }
    },
  },
  methods: {
    async tryAgainClicked() {
      this.loading = true;
      await this.$store.dispatch("commission", {
        uploadConfig: this.$route.query.fail === COMMISSION_STAGE.FIRST,
      });
      this.loading = false;
    },
    mapPhaseNames(phase) {
      const _phase = Array.isArray(phase) ? phase : [phase];

      const map = {
        R: "L1",
        W: "L2",
        B: "L3",
      };
      if (!this.$store.getters.is2P && !this.$store.getters.isRWB) {
        return _phase
          .map((p) => {
            // R, W, B -> L1, L2, L3
            if (/^[RWB]$/.test(p)) {
              return map[p];
            }
            // R1, W1, B1 -> L1-1, L2-1, L3-1
            if (/^[RWB]\d$/.test(p)) {
              const [color, index] = p.split("");
              return `${map[color]}-${index}`;
            }
            return p;
          })
          .join(", ");
      } else {
        return _phase.join(", ");
      }
    },
    mapAddress(phaseName) {
      const map = {
        R: "L1",
        W: "L2",
        B: "L3",
      };
      const phase = phaseName.split(", ");
      return phase
        .map((p) => {
          if (/^[RWB][1-5]$/.test(p)) {
            const phase = map[p[0]];
            const connIdx = Number(p[1]);
            const address =
              this.$store.state.project.connections[connIdx - 1][phase];
            return `${address} (${p})`;
          }
          if (/^L[1-3]-[1-5]$/.test(p)) {
            const [phase, connIdx] = p.split("-");
            const address =
              this.$store.state.project.connections[connIdx - 1][phase];
            return `${address} (${p})`;
          }

          return p;
        })
        .join(", ");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 36px;
  }

  .logs {
    border-top: 1px solid #000;
    padding-top: 9px;
    margin-bottom: 42px;

    .log {
      font-style: italic;
      margin-bottom: 0.5em;
    }
  }

  .yellow-button {
    margin-top: 20px;
  }
}
</style>
