<template>
  <div class="content">
    <div class="title">G'day!</div>
    <div class="description">Welcome to the Solshare commissioning app.</div>
    <yellow-button to="/login" data-tn="good-day-start"> START </yellow-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content {
  color: #fff;
  background: #005149;
  flex: 1;

  .title {
    font-size: 56px;
    margin: 115px 0 63px 0;
    text-align: center;
    font-family: "Helvetica Thin", sans-serif;
  }

  .description {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 104px;
    text-align: center;
    font-family: "Helvetica Light", sans-serif;
  }
}
</style>
